import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ChatProfileInfo: () => import('../../components/ChatProfileInfo.vue' /* webpackChunkName: "components/chat-profile-info" */).then(c => wrapFunctional(c.default || c)),
  ConversationLanguageModal: () => import('../../components/ConversationLanguageModal.vue' /* webpackChunkName: "components/conversation-language-modal" */).then(c => wrapFunctional(c.default || c)),
  GifSearch: () => import('../../components/GifSearch.vue' /* webpackChunkName: "components/gif-search" */).then(c => wrapFunctional(c.default || c)),
  HistoryRecentMessages: () => import('../../components/HistoryRecentMessages.vue' /* webpackChunkName: "components/history-recent-messages" */).then(c => wrapFunctional(c.default || c)),
  ModerationModal: () => import('../../components/ModerationModal.vue' /* webpackChunkName: "components/moderation-modal" */).then(c => wrapFunctional(c.default || c)),
  Queue: () => import('../../components/Queue.vue' /* webpackChunkName: "components/queue" */).then(c => wrapFunctional(c.default || c)),
  StatsTable: () => import('../../components/StatsTable.vue' /* webpackChunkName: "components/stats-table" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
